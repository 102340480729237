/* General styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 70%; /* Increase this if necessary */
  max-width: 1000px; /* Increase the max width */
  margin: auto;
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
}

.sign-in-button,
.sign-out-button,
.submit-button,
button {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 300px; /* Increase the button max-width */
  margin: 10px auto;
  width: 100%;
}

.sign-in-button:hover,
.sign-out-button:hover,
.submit-button:hover,
button:hover {
  background-color: #357ae8;
}

.sign-out-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.sign-out-button {
  background-color: #db4437;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out-button:hover {
  background-color: #c1351d;
}

.form-container {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transcription-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group.full-width {
  flex: 0 0 100%;
}

.form-group label {
  margin-bottom: 5px;
  color: #333;
}

.form-group input[type="text"],
.form-group input[type="file"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  user-select: none;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.custom-radio input:checked ~ .radiomark {
  background-color: #007bff; /* Change this to your primary color */
}

.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .radiomark:after {
  display: block;
}

.custom-radio .radiomark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.output-section {
  margin-top: 20px;
  width: 100%;
}

.output-textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  user-select: none;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #007bff; /* Change this to your primary color */
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
